<template>
  <div>
    <el-card>
      <!-- 通道发送统计 -->
      <!-- 查询条件 -->
      <el-form :model="searchForm" ref="searchFormRef" inline>
        <el-form-item :label="$t('时间段')" prop="">
          <el-date-picker
            v-model="searchForm.startTime"
            type="date"
            :placeholder="$t('开始时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
          -
          <el-date-picker
            v-model="searchForm.endTime"
            type="date"
            :placeholder="$t('结束时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <!-- <el-form-item :label="$t('用户账号')" prop="">
          <el-input
            v-model.trim="searchForm.userName"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.userName = ''"
            style="width: 200px"
          />
        </el-form-item> -->
        <el-form-item :label="$t('产品编号')" prop="">
          <el-input
            v-model.trim="searchForm.appId"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.appId = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('签名')" prop="">
          <el-input
            v-model.trim="searchForm.signature"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.signature = ''"
            style="width: 200px"
          />
        </el-form-item>
        <!-- <el-form-item :label="$t('通道名称')" prop="">
          <el-input
            v-model.trim="searchForm.channelName"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.channelName = ''"
            style="width: 200px"
          />
        </el-form-item> -->
        <!-- <el-form-item :label="$t('通道编号')" prop="">
          <el-input
            v-model.trim="searchForm.channelSn"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.channelSn = ''"
            style="width: 200px"
          />
        </el-form-item> -->
        <el-form-item label="" prop="">
          <el-button type="primary" @click="query"
            ><i class="fa-solid fa-magnifying-glass"></i
            >{{ $t("查询") }}</el-button
          >
          <el-button type="primary" @click="exportExcel" :loading="btnLoading"
            ><i class="fa-solid fa-download"></i>{{ $t("导出数据") }}</el-button
          >
        </el-form-item>
      </el-form>

      <!-- 表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        class="elTable"
        height="var(--height)"
        v-loading="loading"
      >
        <template #empty>
          <el-empty description="" />
        </template>
        <el-table-column
          prop="billTime"
          :label="$t('统计时间')"
          align="center"
        />
        <el-table-column
          prop="userName"
          :label="$t('用户账号')"
          align="center"
        />
        <el-table-column prop="appId" :label="$t('应用编号')" align="center" />
        <el-table-column prop="signature" :label="$t('签名')" align="center" />
        <!-- <el-table-column
          prop="channelName"
          :label="$t('通道名称')"
          align="center"
        /> -->
        <!-- <el-table-column
          prop="channelSn"
          :label="$t('通道编号')"
          align="center"
        /> -->
        <el-table-column
          prop="sendCount"
          :label="$t('发送量')"
          align="center"
        />
        <el-table-column
          prop="successCount"
          label="成功量"
          align="center"
        />
      </el-table>
      <!-- 分页 -->
      <div style="margin: 0 auto; overflow-x: auto; padding: 20px 0">
        <el-pagination
          v-model:currentPage="searchForm.page"
          v-model:page-size="searchForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          :disabled="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="query"
          @current-change="query"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "signature",
};
</script>
<script setup>
import dayjs from "dayjs";
import { reactive, onBeforeMount, ref } from "vue";
import {
  getSignatureStatisticsList,
  exportSignatureStatisticsList,
} from "@/http/api/statisticalManage.js";
import { binaryToExcelExport } from "@/utils/tool.js";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

// 查询表单
const searchForm = reactive({
  startTime: dayjs().startOf("date").format(),
  endTime: dayjs().startOf("date").format(),
  userName: "",
  appId: "",
  signature: "",
  channelSn: "",
  channelName: "",
  page: 1,
  pageSize: 100,
});
// 表格数据
const loading = ref(false);
const tableData = ref([]);
let total = ref(0);
onBeforeMount(async () => {
  query();
});
const query = async () => {
  loading.value = true;
  const res = await getSignatureStatisticsList(searchForm);
  if (res.code === 0) {
    tableData.value = res.data.list || [];
    total.value = res.data.total;
  }
  loading.value = false;
};
//===============================导出数据===============================
const btnLoading = ref(false);
const exportExcel = async () => {
  btnLoading.value = true;
  const res = await exportSignatureStatisticsList(searchForm);
  // if (res && res instanceof Blob) {
  //   binaryToExcelExport(res, t("签名统计报表"));
  // }
  btnLoading.value = false;
};
</script>
  
<style lang="scss" scoped>
.elTable {
  --height: calc(100vh - 250px);
}
::v-deep(label) {
  margin-bottom: 0;
}
</style>
  